
// Dev config
export default {
  apiKey: "AIzaSyBgTne5DXHHgNOmdOJ2IohgeNNTqokP8bg",
  authDomain: "lighthouse-dev-dfa1a.firebaseapp.com",
  databaseURL: "https://lighthouse-dev-dfa1a.firebaseio.com",
  projectId: "lighthouse-dev-dfa1a",
  storageBucket: "lighthouse-dev-dfa1a.appspot.com",
  messagingSenderId: "777512403665",
  appId: "1:777512403665:web:b8d1e3ed05966cb54d3bf0",
  measurementId: "G-Y7XLJ0RGQL"
};


// Prod config
// export default {
//   apiKey: "AIzaSyCMwtOsmJOoS016LG6B4sAtnGB6uuoBKWA",
//   authDomain: "lighthouse-7d374.firebaseapp.com",
//   databaseURL: "https://lighthouse-7d374.firebaseio.com",
//   projectId: "lighthouse-7d374",
//   storageBucket: "lighthouse-7d374.appspot.com",
//   messagingSenderId: "561313906483",
//   appId: "1:561313906483:web:28159ed35ae88b202d0846",
//   measurementId: "G-RW0KYTWKP6"
// };
